@import "@mantine/core/styles.css";
@import "@mantine/dates/styles.css";
@import "@mantine/notifications/styles.css";
@import "@mantine/code-highlight/styles.css";
@import "mantine-datatable/styles.css";
@import "@mantine/spotlight/styles.css";

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

@layer tailwind {
  @tailwind base;
}
